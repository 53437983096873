import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Authenticator, ThemeProvider as AmplifyThemeProvider, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {
  CssBaseline,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  Alert
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import LogoutIcon from '@mui/icons-material/Logout';

import MapComponent from './MapComponent';
import Sidebar from './Sidebar';
import LogoComponent from './LogoComponent';

const drawerWidth = 240;
const splitBarWidth = 10;

const theme = createTheme({
  palette: {
    primary: { main: '#f2f3f3' },
    secondary: { main: '#484848' },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const amplifyTheme = {
  name: 'Auth Custom Theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: '#f2f3f3',
          80: '#484848',
          100: '#000000'
        }
      }
    },
    components: {
      authenticator: {
        container: {
          paddingTop: '0',
        },
      },
      button: {
        primary: {
          backgroundColor: '{colors.brand.primary.80}',
          color: '{colors.brand.primary.10}',
        },
      },
      fieldcontrol: {
        _focus: {
          boxShadow: '0 0 0 2px {colors.brand.primary.80}',
        },
      },
    },
  },
};

const MainApp = ({ signOut, user }) => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [runs, setRuns] = useState([]);
  const [selectedRun, setSelectedRun] = useState(null);
  const [selectedTriggerId, setSelectedTriggerId] = useState(null);
  const [splitPosition, setSplitPosition] = useState(50);
  const [error, setError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(0);

  const containerRef = useRef(null);
  const sidebarRef = useRef(null);

  const theme = createTheme({
    palette: {
      primary: { main: '#f2f3f3' },
      secondary: { main: '#484848' },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });

  const amplifyTheme = {
    name: 'Auth Custom Theme',
    tokens: {
      colors: {
        brand: {
          primary: {
            10: '#f2f3f3',
            80: '#484848',
            100: '#000000'
          }
        }
      },
      components: {
        authenticator: {
          container: {
            paddingTop: '0',
          },
        },
        button: {
          primary: {
            backgroundColor: '{colors.brand.primary.80}',
            color: '{colors.brand.primary.10}',
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: '0 0 0 2px {colors.brand.primary.80}',
          },
        },
      },
    },
  };

  const fetchRuns = useCallback(async () => {
    try {
      setLoading(true);
      const backendUrl = `https://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api/runs`;
      const response = await fetch(backendUrl);
      if (!response.ok) {
        throw new Error(`https error! status: ${response.status}`);
      }
      const data = await response.json();
      setRuns(data);
    } catch (e) {
      console.error("Error fetching runs:", e);
      setError("Failed to fetch runs. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRuns();
  }, [fetchRuns]);

  const handleDrawerToggle = () => setOpen(!open);
  
  const handleRunSelect = (run) => {
    setSelectedRun(run);
    setSelectedTriggerId(null);
    setOpen(false);
  };

  const handleSplitChange = useCallback((clientX) => {
    if (containerRef.current && isDragging) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const newPosition = ((clientX - containerRect.left) / containerRect.width) * 100;
      setSplitPosition(Math.max(10, Math.min(90, newPosition)));
    }
  }, [isDragging]);

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback((e) => {
    handleSplitChange(e.clientX);
  }, [handleSplitChange]);

  const handleTouchMove = useCallback((e) => {
    handleSplitChange(e.touches[0].clientX);
  }, [handleSplitChange]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchend', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleTouchMove, handleMouseUp]);

  useEffect(() => {
    const updateSidebarWidth = () => {
      if (sidebarRef.current) {
        setSidebarWidth(sidebarRef.current.offsetWidth);
      }
    };

    updateSidebarWidth();
    window.addEventListener('resize', updateSidebarWidth);

    const resizeObserver = new ResizeObserver(updateSidebarWidth);
    if (sidebarRef.current) {
      resizeObserver.observe(sidebarRef.current);
    }

    return () => {
      window.removeEventListener('resize', updateSidebarWidth);
      if (sidebarRef.current) {
        resizeObserver.unobserve(sidebarRef.current);
      }
    };
  }, []);

  const renderDrawerContent = () => (
    <>
      <Typography variant="h6" sx={{ p: 2, fontWeight: 'bold', color: 'secondary.main' }}>Runs</Typography>
      <Divider />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ m: 2 }}>{error}</Alert>
      ) : (
        <List>
          {runs.map((run) => (
            <ListItem 
              button 
              key={run.run_id} 
              onClick={() => handleRunSelect(run)}
              selected={selectedRun && selectedRun.run_id === run.run_id}
            >
              <ListItemText 
                primary={run.run_name} 
                secondary={new Date(run.start_time).toLocaleString()}
              />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <AppBar position="static">
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <LogoComponent />
              <Divider orientation="vertical" flexItem sx={{ mx: 2, height: '40px' }} />
              <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold', color: 'secondary.main' }}>
                HCView
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ mr: 2, color: 'secondary.main' }}>
                Hello, {user.username}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={signOut}
                startIcon={<LogoutIcon />}
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Sign Out
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }} ref={containerRef}>
          <Drawer
            variant="temporary"
            open={open}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth} }}
          >
            {renderDrawerContent()}
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
            <Box sx={{ width: `calc(${splitPosition}% - ${splitBarWidth / 2}px)`, height: '100%', overflow: 'hidden' }}>
              <MapComponent 
                selectedRun={selectedRun} 
                splitPosition={splitPosition} 
                selectedTriggerId={selectedTriggerId}
                setSelectedTriggerId={setSelectedTriggerId}
                handleDrawerToggle={handleDrawerToggle}
                handleKeyPress={true}
              />
            </Box>
            <Box
              sx={{
                width: `${splitBarWidth}px`,
                cursor: 'col-resize',
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover, &:active': { 
                  backgroundColor: theme.palette.primary.dark,
                },
                transition: 'background-color 0.2s',
              }}
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
            >
              <DragHandleIcon 
                sx={{
                  transform: 'rotate(90deg)',
                  color: theme.palette.secondary.main,
                  '&:hover': { color: theme.palette.secondary.dark },
                }} 
              />
            </Box>
            <Box sx={{ width: `calc(${100 - splitPosition}% - ${splitBarWidth / 2}px)`, height: '100%', overflow: 'auto' }}>
              <Sidebar 
                selectedRun={selectedRun} 
                selectedTriggerId={selectedTriggerId}
                width={sidebarWidth}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const LoginHeader = () => (
  <Box 
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    }}
  >
    <img
      src="/hcrobotics.png"
      alt="HCRobotics Logo"
      style={{ height: '50px', marginRight: '15px' }}
    />
    <Divider orientation="vertical" flexItem sx={{ mx: 2, height: '40px' }} />
    <Typography variant="h5" fontWeight="bold" sx={{ color: '#484848' }}>
      HCView
    </Typography>
  </Box>
);

const AuthenticatedApp = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return user ? (
    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
      <MainApp signOut={signOut} user={user} />
    </Box>
  ) : null;
};

const App = () => {
  return (
    <AmplifyThemeProvider theme={amplifyTheme}>
      <Authenticator.Provider>
        <Box
          sx={{
            height: '100vh',
            width: '100vw',
            backgroundImage: 'url(/hcview-collage.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <style>
            {`
              [data-amplify-authenticator] {
                --amplify-components-authenticator-router-border-width: 0;
                --amplify-components-authenticator-router-background-color: rgba(255, 255, 255, 0.8);
                --amplify-components-button-primary-background-color: #484848;
                --amplify-components-button-primary-color: #f2f3f3;
                --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px #484848;
              }
              [data-amplify-authenticator] [data-amplify-router] {
                border-radius: 4px;
              }
              [data-amplify-authenticator] [data-amplify-container] {
                padding-top: 0 !important;
              }
            `}
          </style>
          <Authenticator
            hideSignUp
            loginMechanisms={['username']}
            components={{
              Header: LoginHeader,
            }}
          >
            <AuthenticatedApp />
          </Authenticator>
        </Box>
      </Authenticator.Provider>
    </AmplifyThemeProvider>
  );
};

export default App;