import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress,
  ImageList,
  ImageListItem,
  Paper,
  Grid
} from '@mui/material';
import FullScreenViewer from './FullScreenViewer';

const Sidebar = ({ selectedRun, selectedTriggerId }) => {
  const [imageData, setImageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [selectedFullScreenImage, setSelectedFullScreenImage] = useState(null);
  const [cols, setCols] = useState(1);
  const sidebarRef = useRef(null);
  const resizeObserverRef = useRef(null);

  const backendUrl = `https://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}`;

  const fetchImageData = useCallback(async () => {
    if (!selectedRun || !selectedTriggerId) return;

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${backendUrl}/api/images/${selectedRun.run_id}/trigger/${selectedTriggerId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch image data');
      }
      const data = await response.json();
      setImageData(data);
      setSelectedImageIndex(prevIndex => prevIndex < data.length ? prevIndex : 0);
    } catch (err) {
      console.error('Error fetching image data:', err);
      setError('Failed to load image data');
    } finally {
      setLoading(false);
    }
  }, [selectedRun, selectedTriggerId, backendUrl]);

  useEffect(() => {
    fetchImageData();
  }, [fetchImageData]);

  const handleImageClick = useCallback((index) => {
    setSelectedImageIndex(index);
  }, []);

  const handleImageDoubleClick = useCallback((item) => {
    setSelectedFullScreenImage(item);
    setFullScreenOpen(true);
  }, []);

  const handleFullScreenClose = useCallback(() => {
    setFullScreenOpen(false);
    setSelectedFullScreenImage(null);
  }, []);

  const updateCols = useCallback(() => {
    if (sidebarRef.current) {
      const width = sidebarRef.current.clientWidth;
      const targetImageWidth = 230; // Reduced from 250 to make images smaller
      const newCols = Math.max(1, Math.floor(width / targetImageWidth));
      setCols(newCols);
    }
  }, []);

  useEffect(() => {
    // Initialize ResizeObserver only after images are loaded
    if (imageData.length > 0 && !resizeObserverRef.current && sidebarRef.current) {
      resizeObserverRef.current = new ResizeObserver(updateCols);
      resizeObserverRef.current.observe(sidebarRef.current);
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        resizeObserverRef.current = null;
      }
    };
  }, [imageData, updateCols]);

  // if (!selectedRun) {
  //   return (
  //     <Box sx={{ p: 2 }}>
  //       <Typography>Please select a run to view details.</Typography>
  //     </Box>
  //   );
  // }

  return (
    <Box 
      ref={sidebarRef} 
      sx={{ 
        height: '100%', 
        overflow: 'auto', 
        p: 2, 
        backgroundColor: 'white', // Add white background
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', // Optional: add some shadow for depth
      }}
    >
      {!selectedRun ? (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%',
            p: 2,
          }}
        >
          <Typography variant="h6" color="text.secondary">
            Please select a run to view details.
          </Typography>
        </Box>
      ) : (
        <Box sx={{ p: 2 }}>
          <Paper elevation={3} sx={{ p: 2, mb: 2, backgroundColor: 'white' }}>
            <Typography variant="h6" gutterBottom>Run {selectedRun.run_id}</Typography>
            <Typography>Run Name: {selectedRun.run_name}</Typography>
            <Typography>Start Time: {new Date(selectedRun.start_time).toLocaleString()}</Typography>
            <Typography>Description: {selectedRun.description || 'N/A'}</Typography>
            <Typography>Current Trigger ID: {selectedTriggerId || 'None selected'}</Typography>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, backgroundColor: 'white' }}>
            <Typography variant="h6" gutterBottom>Images</Typography>
            {loading ? (
              <CircularProgress />
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <>
                <ImageList sx={{ width: '100%', height: 'auto', maxHeight: 'calc(100vh - 400px)' }} cols={cols} gap={8}>
                  {imageData.map((item, index) => (
                    <ImageListItem 
                      key={item.id} 
                      onClick={() => handleImageClick(index)}
                      onDoubleClick={() => handleImageDoubleClick(item)}
                      sx={{
                        cursor: 'pointer',
                        border: index === selectedImageIndex ? '2px solid blue' : 'none',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        aspectRatio: '1 / 1',
                        '& img': {
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        },
                      }}
                    >
                      <img
                        src={`${backendUrl}/api/images/${item.run_id}/${item.id}/thumbnail`}
                        alt={`Image ${item.id}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
                {imageData[selectedImageIndex] && (
                  <Paper elevation={1} sx={{ p: 2, mt: 2, backgroundColor: 'white' }}>
                    <Typography variant="subtitle1" gutterBottom>Image Details</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography>Camera: {imageData[selectedImageIndex].camera_name}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>Timestamp: {new Date(imageData[selectedImageIndex].timestamp).toLocaleString()}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>Frame ID: {imageData[selectedImageIndex].frame_id}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>Exposure: {imageData[selectedImageIndex].exposure_us} µs</Typography>
                      </Grid>
                      {imageData[selectedImageIndex].encoderTickSpeed !== null && (
                        <Grid item xs={12} sm={6}>
                          <Typography>Speed: {calculateSpeed(imageData[selectedImageIndex].encoderTickSpeed, selectedRun.wheel_encoder_ticks_per_rotation, selectedRun.wheel_static_radius).toFixed(2)} mph</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                )}
              </>
            )}
          </Paper>
        </Box>
      )}
      {selectedFullScreenImage && (
        <FullScreenViewer
          open={fullScreenOpen}
          handleClose={handleFullScreenClose}
          imageUrl={selectedFullScreenImage.full_res_url}
          runId={selectedFullScreenImage.run_id}
          imageId={selectedFullScreenImage.id}
        />
      )}
    </Box>
  );
};

// Helper function to calculate speed
const calculateSpeed = (encoderTickSpeed, wheelEncoderTicksPerRotation, wheelStaticRadius) => {
  if (!encoderTickSpeed || !wheelEncoderTicksPerRotation || !wheelStaticRadius) return 0;
  const radiusInMiles = wheelStaticRadius / 1609344; // Convert to miles
  const rotationsPerSecond = encoderTickSpeed / wheelEncoderTicksPerRotation;
  return rotationsPerSecond * radiusInMiles * 2 * Math.PI * 3600; // Speed in mph
};

export default Sidebar;