import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, IconButton, Modal, CircularProgress, Typography, Slider } from '@mui/material';
import { Close } from '@mui/icons-material';
import OpenSeadragon from 'openseadragon';

const FullScreenViewer = ({ open, handleClose, imageUrl, runId, imageId }) => {
  const viewerRef = useRef(null);
  const [viewer, setViewer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);

  const backendUrl = `https://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}`;

  const initializeViewer = useCallback(async () => {
    if (viewerRef.current && !viewer) {
      try {
        const isDziImage = imageUrl.endsWith('.dzi');
        let tileSource;

        if (isDziImage) {
          const response = await fetch(`${backendUrl}/api/images/${runId}/${imageId}/full`);
          const dziXml = await response.text();
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(dziXml, 'text/xml');
          const imageElement = xmlDoc.getElementsByTagName('Image')[0];
          const tileUrl = imageElement.getAttribute('Url');

          tileSource = {
            type: 'dzi',
            width: parseInt(imageElement.getElementsByTagName('Size')[0].getAttribute('Width')),
            height: parseInt(imageElement.getElementsByTagName('Size')[0].getAttribute('Height')),
            tileSize: parseInt(imageElement.getAttribute('TileSize')),
            overlap: parseInt(imageElement.getAttribute('Overlap')),
            getTileUrl: (level, x, y) => `${backendUrl}${tileUrl}${level}/${x}_${y}.png`,
          };
        } else {
          tileSource = {
            type: 'image',
            url: `${backendUrl}/api/images/${runId}/${imageId}/full`,
            buildPyramid: false,
            loadTilesWithAjax: true,
            ajaxHeaders: {
              'Cache-Control': 'no-store',
              'Pragma': 'no-cache'
            }
          };
        }
        
        const newViewer = OpenSeadragon({
          element: viewerRef.current,
          tileSources: tileSource,
          showNavigationControl: false,
          defaultZoomLevel: 1,
          minZoomLevel: 0.5,
          maxZoomLevel: 7,
          visibilityRatio: 1,
          constrainDuringPan: true,
          immediateRender: true,
          preload: true,
          crossOriginPolicy: 'Anonymous',
          // drawer: 'canvas',
          // Add the subPixelRoundingForTransparency setting to remove white lines
          subPixelRoundingForTransparency: OpenSeadragon.SUBPIXEL_ROUNDING_OCCURRENCES.ALWAYS,
          gestureSettingsTouch: {
            pinchToZoom: true,
            flickEnabled: true,
            flickMinSpeed: 20,
            flickMomentum: 0.4
          }
        });

        newViewer.addHandler('open', () => {
          setIsLoading(false);
          setError(null);
          newViewer.viewport.fitBounds(newViewer.world.getItemAt(0).getBounds(), true);
        });

        newViewer.addHandler('open-failed', (event) => {
          setIsLoading(false);
          setError(`Failed to load image: ${event.message || 'Unknown error'}`);
        });

        newViewer.addHandler('tile-load-failed', (event) => {
          console.error('Tile load failed:', event);
        });

        newViewer.addHandler('zoom', (event) => {
          setZoomLevel(Math.round(event.zoom * 100) / 100);
        });

        setViewer(newViewer);
      } catch (err) {
        setIsLoading(false);
        setError(`Failed to initialize image viewer: ${err.message || 'Unknown error'}`);
      }
    }
  }, [backendUrl, imageUrl, runId, imageId, viewer]);

  useEffect(() => {
    if (open && imageUrl) {
      setIsLoading(true);
      setError(null);
      const timer = setTimeout(initializeViewer, 100);

      return () => {
        clearTimeout(timer);
        if (viewer) {
          viewer.destroy();
          setViewer(null);
        }
      };
    }
  }, [open, imageUrl, initializeViewer, viewer]);

  useEffect(() => {
    if (viewer && viewer.canvas) {
      viewer.canvas.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
    }
  }, [viewer, brightness, contrast]);

  const handleBrightnessChange = (event, newValue) => {
    setBrightness(newValue);
  };

  const handleContrastChange = (event, newValue) => {
    setContrast(newValue);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="full-screen-image-viewer"
      aria-describedby="view full resolution image"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95vw',
        height: '95vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
            zIndex: 1,
          }}
        >
          <Close />
        </IconButton>
        <Box
          sx={{
            position: 'absolute',
            left: 16,
            top: 16,
            zIndex: 1,
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
          }}
        >
          <Typography variant="body2">Zoom: {zoomLevel}x</Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: 16,
            bottom: 16,
            zIndex: 1,
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            width: '200px',
          }}
        >
          <Typography variant="body2">Brightness: {brightness}%</Typography>
          <Slider
            value={brightness}
            onChange={handleBrightnessChange}
            min={50}
            max={300}
            step={1}
            aria-labelledby="brightness-slider"
            sx={{ color: 'white' }}
          />
          <Typography variant="body2">Contrast: {contrast}%</Typography>
          <Slider
            value={contrast}
            onChange={handleContrastChange}
            min={50}
            max={300}
            step={1}
            aria-labelledby="contrast-slider"
            sx={{ color: 'white' }}
          />
        </Box>
        {isLoading && (
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            maxWidth: '80%',
          }}>
            <Typography color="error" variant="body1">Error: {error}</Typography>
          </Box>
        )}
        <div ref={viewerRef} style={{ width: '100%', height: '100%' }} />
      </Box>
    </Modal>
  );
};

export default FullScreenViewer;