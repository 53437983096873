import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './amplifyConfig';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// H(H(V(7,V(3,0)), V(5,V(2,8))), H(V(1,V(6,4)),9))