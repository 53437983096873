import React from 'react';
import { Box, Typography } from '@mui/material';

const LogoComponent = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <img
        src="/hcrobotics.png"
        alt="HCRobotics Logo"
        style={{ height: '50px', marginRight: '0px' }}
      />
    </Box>
  );
};

export default LogoComponent;