import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Box, Button, Typography } from '@mui/material';
import { Satellite, Terrain, Speed, SpeedOutlined, ArrowBack, ArrowForward, List } from '@mui/icons-material';
import SpeedLegend from './SpeedLegend';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapComponent = ({ selectedRun, splitPosition, selectedTriggerId, setSelectedTriggerId, handleDrawerToggle, handleKeyPress }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [trajectoryData, setTrajectoryData] = useState([]);
  const [triggerData, setTriggerData] = useState([]);
  const [satelliteView, setSatelliteView] = useState(false);
  const [showSpeedOverlay, setShowSpeedOverlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasSpeedData, setHasSpeedData] = useState(false);
  const [isCompactView, setIsCompactView] = useState(false);

  const backendUrl = useMemo(() => `https://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}`, []);

  const calculateSpeed = useCallback((encoderTickSpeed, wheelEncoderTicksPerRotation, wheelStaticRadius) => {
    if (!encoderTickSpeed || !wheelEncoderTicksPerRotation || !wheelStaticRadius) return 0;
    const radiusInMiles = wheelStaticRadius / 1609344;
    const rotationsPerSecond = encoderTickSpeed / wheelEncoderTicksPerRotation;
    return rotationsPerSecond * radiusInMiles * 2 * Math.PI * 3600;
  }, []);

  const fetchRunData = useCallback(async () => {
    if (!selectedRun) return;

    setLoading(true);
    setError(null);

    try {
      const [trajectoryResponse, triggerResponse] = await Promise.all([
        fetch(`${backendUrl}/api/trajectories/${selectedRun.run_id}`),
        fetch(`${backendUrl}/api/triggers/${selectedRun.run_id}`)
      ]);

      if (!trajectoryResponse.ok || !triggerResponse.ok) {
        throw new Error('Failed to fetch run data');
      }

      const trajectoryData = await trajectoryResponse.json();
      const triggerData = await triggerResponse.json();

      setTrajectoryData(trajectoryData);
      setTriggerData(triggerData);

      if (triggerData.length > 0) {
        setSelectedTriggerId(triggerData[0].id);
      }

      const speedDataExists = trajectoryData.some(point => point.encoderTickSpeed !== null);
      setHasSpeedData(speedDataExists);
      setShowSpeedOverlay(false);

      updateMapData(trajectoryData, triggerData);
      
      if (trajectoryData.length > 0) {
        const coordinates = trajectoryData.map(point => [point.longitude, point.latitude]);
        const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
        mapRef.current.fitBounds(bounds, { padding: 50 });
      }
    } catch (err) {
      console.error('Error fetching run data:', err);
      setError('Failed to load run data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [selectedRun, setSelectedTriggerId, backendUrl]);

  useEffect(() => {
    fetchRunData();
  }, [fetchRunData, selectedRun]);

  const updateMarkerPosition = useCallback(() => {
    if (mapRef.current && mapRef.current.isStyleLoaded() && selectedTriggerId) {
      const selectedTrigger = triggerData.find(trigger => trigger.id === selectedTriggerId);
      if (selectedTrigger) {
        const newPosition = [selectedTrigger.longitude, selectedTrigger.latitude];
        
        if (markerRef.current) {
          markerRef.current.setLngLat(newPosition);
        } else {
          markerRef.current = new mapboxgl.Marker()
            .setLngLat(newPosition)
            .addTo(mapRef.current);
        }

        mapRef.current.easeTo({
          center: newPosition,
          duration: 1000,
        });
      }
    }
  }, [selectedTriggerId, triggerData]);

  useEffect(() => {
    updateMarkerPosition();
  }, [updateMarkerPosition, selectedTriggerId]);

  const initializeMap = useCallback(() => {
    if (!mapRef.current && mapContainerRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-95.7129, 37.0902],
        zoom: 3
      });

      mapRef.current.on('load', () => {
        mapRef.current.addSource('trajectory', {
          type: 'geojson',
          data: { type: 'Feature', properties: {}, geometry: { type: 'LineString', coordinates: [] } }
        });

        mapRef.current.addLayer({
          id: 'trajectory-line',
          type: 'line',
          source: 'trajectory',
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: { 'line-color': '#007cbf', 'line-width': 4 }
        });

        mapRef.current.addSource('speed', {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: [] }
        });

        mapRef.current.addLayer({
          id: 'speed-line',
          type: 'line',
          source: 'speed',
          layout: { 'line-join': 'round', 'line-cap': 'round', 'visibility': 'none' },
          paint: {
            'line-color': [
              'interpolate',
              ['linear'],
              ['get', 'speed'],
              0, 'blue',
              25, 'yellow',
              50, 'red'
            ],
            'line-width': 4
          }
        });

        mapRef.current.addSource('triggers', {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: [] },
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50
        });

        mapRef.current.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'triggers',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#51bbd6',
              100,
              '#f28cb1',
              750,
              '#f1f075'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40
            ]
          }
        });

        mapRef.current.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'triggers',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
          }
        });

        mapRef.current.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'triggers',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#FF0000',
            'circle-radius': 5,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#ffffff'
          }
        });

        mapRef.current.on('click', 'unclustered-point', (e) => {
          const features = mapRef.current.queryRenderedFeatures(e.point, { layers: ['unclustered-point'] });
          if (features.length > 0) {
            setSelectedTriggerId(features[0].properties.id);
          }
        });

        mapRef.current.on('mouseenter', 'unclustered-point', () => {
          mapRef.current.getCanvas().style.cursor = 'pointer';
        });

        mapRef.current.on('mouseleave', 'unclustered-point', () => {
          mapRef.current.getCanvas().style.cursor = '';
        });

        updateMapData();
      });
    }
  }, [setSelectedTriggerId]);

  useEffect(() => {
    initializeMap();
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [initializeMap]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [splitPosition]);

  const updateMapData = useCallback((trajectoryData, triggerData) => {
    if (mapRef.current && mapRef.current.isStyleLoaded()) {
      if (trajectoryData?.length > 0) {
        const coordinates = trajectoryData.map(point => [point.longitude, point.latitude]);
        mapRef.current.getSource('trajectory').setData({
          type: 'Feature',
          properties: {},
          geometry: { type: 'LineString', coordinates }
        });
  
        // Fit map to new run data only when loading a new run
        if (!mapRef.current.initialFitDone) {
          const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
          mapRef.current.fitBounds(bounds, { padding: 50 });
          mapRef.current.initialFitDone = true;
        }
      }
  
      // Update speed data
      if (trajectoryData?.length > 1 && selectedRun) {
        const speedDataExists = trajectoryData.some(point => point.encoderTickSpeed !== null);
        if (speedDataExists) {
          const speedFeatures = trajectoryData.slice(1).map((point, i) => ({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [trajectoryData[i].longitude, trajectoryData[i].latitude],
                [point.longitude, point.latitude]
              ]
            },
            properties: {
              speed: calculateSpeed(
                point.encoderTickSpeed,
                selectedRun.wheel_encoder_ticks_per_rotation,
                selectedRun.wheel_static_radius
              )
            }
          }));
  
          mapRef.current.getSource('speed').setData({
            type: 'FeatureCollection',
            features: speedFeatures
          });
  
          mapRef.current.setPaintProperty('speed-line', 'line-color', [
            'interpolate',
            ['linear'],
            ['get', 'speed'],
            0, 'rgb(0, 0, 255)',
            10, 'rgb(0, 255, 255)',
            20, 'rgb(0, 255, 0)',
            30, 'rgb(255, 255, 0)',
            40, 'rgb(255, 0, 0)',
            50, 'rgb(128, 0, 128)'
          ]);
        } else {
          // If no speed data, set an empty FeatureCollection
          mapRef.current.getSource('speed').setData({
            type: 'FeatureCollection',
            features: []
          });
        }
  
        // Update speed layer visibility
        mapRef.current.setLayoutProperty('speed-line', 'visibility', showSpeedOverlay && speedDataExists ? 'visible' : 'none');
        setHasSpeedData(speedDataExists);
      }
  
      // Update trigger data
      if (triggerData?.length > 0) {
        const features = triggerData.map(trigger => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [trigger.longitude, trigger.latitude]
          },
          properties: {
            id: trigger.id,
            type: trigger.type
          }
        }));
  
        mapRef.current.getSource('triggers').setData({
          type: 'FeatureCollection',
          features: features
        });
  
        // Remove old marker if it exists
        if (markerRef.current) {
          markerRef.current.remove();
        }
  
        // Add new marker for the first trigger
        const firstTrigger = triggerData[0];
        markerRef.current = new mapboxgl.Marker()
          .setLngLat([firstTrigger.longitude, firstTrigger.latitude])
          .addTo(mapRef.current);

        // Set the selectedTriggerId to the first trigger
        setSelectedTriggerId(firstTrigger.id);
      }
    }
  }, [calculateSpeed, selectedRun, showSpeedOverlay, setSelectedTriggerId]);

  useEffect(() => {
    if (mapRef.current && mapRef.current.isStyleLoaded()) {
      updateMapData(trajectoryData, triggerData);
    }
  }, [updateMapData, trajectoryData, triggerData]);

  const toggleMapStyle = useCallback(() => {
    if (mapRef.current) {
      const currentStyle = mapRef.current.getStyle();
      const newStyle = satelliteView
        ? 'mapbox://styles/mapbox/streets-v11'
        : 'mapbox://styles/mapbox/satellite-streets-v11';
      
      const customSourceIds = ['trajectory', 'speed', 'triggers'];
      const customLayerIds = ['trajectory-line', 'speed-line', 'clusters', 'cluster-count', 'unclustered-point'];
      
      const customSources = {};
      const customLayers = [];
  
      // Store custom sources and layers
      customSourceIds.forEach(sourceId => {
        if (currentStyle.sources[sourceId]) {
          customSources[sourceId] = currentStyle.sources[sourceId];
        }
      });
  
      customLayerIds.forEach(layerId => {
        const layer = currentStyle.layers.find(l => l.id === layerId);
        if (layer) {
          customLayers.push(layer);
        }
      });
  
      mapRef.current.once('styledata', () => {
        // Re-add custom sources and layers after style change
        Object.keys(customSources).forEach(sourceId => {
          if (!mapRef.current.getSource(sourceId)) {
            mapRef.current.addSource(sourceId, customSources[sourceId]);
          }
        });
  
        customLayers.forEach(layer => {
          if (!mapRef.current.getLayer(layer.id)) {
            mapRef.current.addLayer(layer);
          }
        });
  
        // Update data for all sources
        updateMapData(trajectoryData, triggerData);
      });
  
      mapRef.current.setStyle(newStyle);
      setSatelliteView(!satelliteView);
    }
  }, [satelliteView, updateMapData, trajectoryData, triggerData]);

  const toggleSpeedOverlay = useCallback(() => {
    setShowSpeedOverlay(prev => {
      const newState = !prev;
      if (mapRef.current) {
        mapRef.current.setLayoutProperty('speed-line', 'visibility', newState ? 'visible' : 'none');
      }
      return newState;
    });
  }, []);

  const navigateTrigger = useCallback((direction) => {
    if (!triggerData.length) return;

    const currentIndex = triggerData.findIndex(t => t.id === selectedTriggerId);
    if (currentIndex === -1) return;

    const newIndex = direction === 'next'
      ? (currentIndex + 1) % triggerData.length
      : (currentIndex - 1 + triggerData.length) % triggerData.length;

    setSelectedTriggerId(triggerData[newIndex].id);
  }, [triggerData, selectedTriggerId, setSelectedTriggerId]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.keyboard.disable();
    }

    if (handleKeyPress) {
      const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          navigateTrigger('prev');
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          navigateTrigger('next');
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [handleKeyPress, navigateTrigger]);

  useEffect(() => {
    const newIsCompactView = splitPosition < 25; // Adjust this threshold as needed
    setIsCompactView(newIsCompactView);
  }, [splitPosition]);

  const buttonSx = useMemo(() => ({
    bgcolor: 'background.paper', 
    color: 'text.primary',
    minWidth: isCompactView ? '40px' : '120px',
    width: isCompactView ? '40px' : 'auto',
    height: isCompactView ? '40px' : 'auto',
    padding: isCompactView ? '8px' : '8px 12px',
    justifyContent: 'flex-start',
    '& .MuiButton-startIcon': {
      margin: isCompactView ? 0 : '0 8px 0 0',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  }), [isCompactView]);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
      <Box sx={{ 
        position: 'absolute', 
        left: 16, 
        top: 16, 
        display: 'flex', 
        flexDirection: 'column',
        gap: 1,
      }}>
        <Button
          variant="contained"
          startIcon={satelliteView ? <Terrain /> : <Satellite />}
          onClick={toggleMapStyle}
          sx={buttonSx}
        >
          {!isCompactView && (satelliteView ? 'Streets' : 'Satellite')}
        </Button>
        {hasSpeedData && (
          <Button
            variant="contained"
            startIcon={showSpeedOverlay ? <Speed /> : <SpeedOutlined />}
            onClick={toggleSpeedOverlay}
            sx={buttonSx}
          >
            {!isCompactView && (showSpeedOverlay ? 'Hide Speed' : 'Show Speed')}
          </Button>
        )}
        <Button
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={() => navigateTrigger('prev')}
          sx={buttonSx}
        >
          {!isCompactView && 'Previous'}
        </Button>
        <Button
          variant="contained"
          startIcon={<ArrowForward />}
          onClick={() => navigateTrigger('next')}
          sx={buttonSx}
        >
          {!isCompactView && 'Next'}
        </Button>
        <Button
          variant="contained"
          startIcon={<List />}
          onClick={handleDrawerToggle}
          sx={buttonSx}
        >
          {!isCompactView && 'Show Runs'}
        </Button>
      </Box>
      {hasSpeedData && showSpeedOverlay && <SpeedLegend isCompactView={isCompactView}/>}
      {loading && (
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 2,
          borderRadius: 1,
        }}>
          <Typography>Loading...</Typography>
        </Box>
      )}
      {error && (
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 2,
          borderRadius: 1,
        }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default MapComponent;