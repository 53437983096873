import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

const SpeedLegend = ({ isCompactView }) => {
  const colorStops = useMemo(() => [
    { color: 'rgb(0,0,255)', position: 0, speed: 0 },
    { color: 'rgb(0,255,255)', position: 20, speed: 10 },
    { color: 'rgb(0,255,0)', position: 40, speed: 20 },
    { color: 'rgb(255,255,0)', position: 60, speed: 30 },
    { color: 'rgb(255,0,0)', position: 80, speed: 40 },
    { color: 'rgb(128,0,128)', position: 100, speed: 50 }
  ], []);

  const gradientString = useMemo(() => 
    isCompactView
      ? `linear-gradient(to top, ${colorStops.map(stop => `${stop.color} ${stop.position}%`).join(', ')})`
      : `linear-gradient(to right, ${colorStops.map(stop => `${stop.color} ${stop.position}%`).join(', ')})`,
  [colorStops, isCompactView]);

  if (isCompactView) {
    return (
      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '8px',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="caption" sx={{ mb: 1, fontSize: '0.7rem', lineHeight: 1 }}>
          Speed
        </Typography>
        <Typography variant="caption" sx={{ mb: 1, fontSize: '0.7rem', lineHeight: 1 }}>
          (mph)
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box 
            sx={{ 
              width: 20,
              height: 150,
              mr: 1,
              background: gradientString,
              position: 'relative'
            }}
          >
            {colorStops.map((stop) => (
              <Box
                key={stop.position}
                sx={{
                  position: 'absolute',
                  left: 0,
                  bottom: `${stop.position}%`,
                  width: '100%',
                  height: 2,
                  backgroundColor: 'white',
                  boxShadow: '0 0 2px rgba(0,0,0,0.5)',
                }}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: 150,
            }}
          >
            {colorStops.slice().reverse().map((stop) => (
              <Typography 
                key={stop.position} 
                variant="caption" 
                sx={{ 
                  textAlign: 'left',
                  fontSize: '0.7rem',
                }}
              >
                {stop.speed}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '8px 12px',
        borderRadius: 1,
        width: 300,
      }}
    >
      <Typography variant="subtitle2" sx={{ mb: 0.5, textAlign: 'center' }}>
        Speed (mph)
      </Typography>
      <Box 
        sx={{ 
          height: 20, 
          mb: 0.5, 
          background: gradientString,
          position: 'relative'
        }}
      >
        {colorStops.map((stop) => (
          <Box
            key={stop.position}
            sx={{
              position: 'absolute',
              top: 0,
              left: `${stop.position}%`,
              width: 2,
              height: '100%',
              backgroundColor: 'white',
              boxShadow: '0 0 2px rgba(0,0,0,0.5)',
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          height: 20,
        }}
      >
        {colorStops.map((stop) => (
          <Typography 
            key={stop.position} 
            variant="caption" 
            sx={{ 
              position: 'absolute',
              left: `${stop.position}%`,
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}
          >
            {stop.speed}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default SpeedLegend;